import React from "react"

export default function Basket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="noun_cart_2102832_4_"
        data-name="noun_cart_2102832 (4)"
        transform="translate(-6 -6)"
      >
        <path
          id="Path_5"
          data-name="Path 5"
          d="M6.917,7.833H8.448l2.295,10.355a3.289,3.289,0,0,0-1.871,3.042,3.133,3.133,0,0,0,2.962,3.275H23.988a.917.917,0,1,0,0-1.833H11.833A1.332,1.332,0,0,1,10.7,21.231a1.332,1.332,0,0,1,1.128-1.442H23.988a.917.917,0,0,0,.863-.606l3.1-8.591a.917.917,0,0,0-.863-1.227H10.665l-.586-2.646A.916.916,0,0,0,9.184,6H6.917a.917.917,0,0,0,0,1.833ZM25.779,11.2l-2.435,6.758H12.568l-1.5-6.758H25.779Z"
          fill="#00313c"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M19.261,50a.917.917,0,0,0,0,1.833h1.164a.917.917,0,1,0,0-1.833Z"
          transform="translate(-6.687 -23.833)"
          fill="#00313c"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M37.713,50a.917.917,0,0,0,0,1.833h1.164a.917.917,0,1,0,0-1.833Z"
          transform="translate(-16.682 -23.833)"
          fill="#00313c"
        />
      </g>
    </svg>
  )
}
