import React from "react"
import Basket from "../Icons/Basket"

export default function AddToBasket({
  addProductToCartHandler,
  product,
  shopifyProductData,
}) {
  let variantQuantity = undefined

  shopifyProductData.map(p =>
    p.variants.edges.map(variant => {
      if (variant.node.sku === product.variation.sku) {
        variantQuantity = variant.node.inventoryQuantity
      }
      return null
    })
  )

  if (variantQuantity === undefined) {
    return (
      <button className="add outofstock checkingstock">Checking stock</button>
    )
  }

  if (variantQuantity === 0) {
    return <button className="add outofstock">Out of stock</button>
  }

  return (
    <button
      className="add"
      onClick={() =>
        addProductToCartHandler(
          product.variation.variantIdBase64,
          product.quantity,
          product.variation.sku
        )
      }
    >
      Add to basket
      <Basket />
    </button>
  )
}
