import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import Cookies from "js-cookie"
import { Link } from "gatsby"
import axios from "axios"

import {
  removeProductFromWishlist,
  updateProductFromWishlist,
  createCartHandler,
  refreshCart,
} from "../../app/actions"
import { addProductsToCart, getCart } from "../../services/shopify"

import AddToBasket from "./AddToBasket"
import Breadcrumb from "../Breadcrumb"
import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock"

import styles from "./styles"
import useAllProducts from "../../hooks/use-all-products"

const useStyles = makeStyles(theme => styles(theme))

const mapStateToProps = state => ({
  wishlist: state.WishlistSettings,
  cartId: state.CartSettings.cartId,
})

const mapDispatchToProps = dispatch => ({
  createCart: () => dispatch(createCartHandler()),
  removeProductFromWishlist: id => dispatch(removeProductFromWishlist(id)),
  updateProductFromWishlist: item => dispatch(updateProductFromWishlist(item)),
  refreshCart: () => dispatch(refreshCart()),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

function Wishlist({
  wishlist,
  removeProductFromWishlist,
  updateProductFromWishlist,
  cartId,
  createCart,
  refreshCart,
}) {
  const classes = useStyles()
  const [status, setStatus] = useState(undefined)
  const [shopifyProductData, setShopifyProductData] = useState([])

  const products = useAllProducts()

  useEffect(() => {
    let cookiesConsent = Cookies.get("cookie_consent_settings")
    if (!cookiesConsent) return
    let frags = cookiesConsent.split(",")
    let wishListCookieOptions = {}
    frags.forEach(value => {
      let pieces = value.split(":")
      // let consented = pieces[1] == "1"
      if (pieces[0] === "a") {
        wishListCookieOptions = { expires: 365 }
      }
    })
    Cookies.set("wishlist", JSON.stringify(wishlist), wishListCookieOptions)
  }, [wishlist])

  useEffect(() => {
    setTimeout(() => setStatus(undefined), 6000)
  }, [status])

  useEffect(() => {
    loadCart()

    const productsWithData = products.filter(product =>
      product.ProductData.variants.find(variant =>
        wishlist.find(w => w.sku === variant.sku)
      )
    )

    let promises = []

    productsWithData.map(product => {
      const { productIdBase64 } = product.ProductData
      const promise = axios({
        method: "post",
        url: `${process.env.GATSBY_BACKEND_URL}/wp-json/brewshopify/v1/product`,
        data: {
          productId: productIdBase64,
        },
      }).then(res => res.data.data.product)

      promises.push(promise)

      return null
    })

    Promise.all(promises).then(data => {
      setShopifyProductData(data)
    })
  }, [])

  function addProductToCartHandler(merchandiseId, quantity, sku) {
    setStatus("locked")
    addProductsToCart({
      cartId,
      products: [
        {
          merchandiseId,
          quantity: Number(quantity),
        },
      ],
    }).then(res => {
      if (res.data.status === "success") {
        setStatus(`cart-success-${sku}`)
        refreshCart()
      } else {
        setStatus(`cart-error-${sku})`)
      }
    })
  }

  function addProductsToCartHandler() {
    setStatus("locked")

    if (!productsWithShopifyData.length) {
      return setStatus("cart-error-noproducts")
    }

    addProductsToCart({
      cartId,
      products: productsWithShopifyData,
    }).then(res => {
      if (res.data.status === "success") {
        setStatus("cart-success-all")
      } else {
        setStatus("cart-error-all")
      }
    })
  }

  function loadCart() {
    if (!cartId) {
      createCart()
    } else {
      loadCartHandler(cartId)
    }
  }

  async function loadCartHandler(cartId) {
    let res = await getCart(cartId)
    if (!res.data.data.id) {
      const cart = await createCart()
      return loadCartHandler(cart.cartId)
    }

    refreshCart()
  }

  function updateQuantityHandler(sku, quantity) {
    updateProductFromWishlist({
      sku,
      quantity,
    })
  }

  const filteredProducts = [...wishlist]
    .map(item => {
      let result = {}

      products.forEach(pr => {
        if (pr && pr.ProductData.variants) {
          pr.ProductData.variants.forEach(variation => {
            if (variation.sku === item.sku) {
              result = {
                data: pr,
                variation,
                quantity: item.quantity,
              }
            }
          })
        }
      })

      return result
    })
    .sort((a, b) => a.variation.sku.localeCompare(b.variation.sku))

  const productsWithShopifyData =
    filteredProducts &&
    filteredProducts
      .map(product => {
        let variantQuantity = undefined

        shopifyProductData.map(p =>
          p.variants.edges.map(variant => {
            if (variant.node.sku === product.variation.sku) {
              variantQuantity = variant.node.inventoryQuantity
            }
            return null
          })
        )

        if (!variantQuantity) return null

        return {
          quantity: Number(product.quantity),
          merchandiseId: product.variation.variantIdBase64,
        }
      })
      .filter(product => product)

  return (
    <>
      <Breadcrumb
        type="category"
        current={{
          title: "Wishlist",
          slug: "wishlist",
          uri: "/wishlist",
        }}
      />
      <section>
        <div className={classes.pageTitle}>
          <div className="wrapper">
            {filteredProducts.length > 0 && (
              <CoreHeadingBlock
                attributes={{
                  textAlign: "left",
                  anchor: "",
                  className: "",
                  content: "Your Wishlist",
                  level: 1,
                  textColor: "primary-navy",
                  backgroundColor: "",
                  __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]}
              />
            )}
            {filteredProducts.length === 0 && (
              <CoreHeadingBlock
                attributes={{
                  textAlign: "left",
                  anchor: "",
                  className: "",
                  content: "Your wishlist is empty",
                  level: 1,
                  textColor: "primary-navy",
                  backgroundColor: "",
                  __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]}
              />
            )}
          </div>
        </div>

        {filteredProducts.length > 0 && (
          <div
            className={`${classes.wishlistWrapper} ${
              status === "locked" ? "locked" : ""
            }`}
          >
            <div className="column-titles">
              <div className="prod">Product</div>
              <div className="quantity">Quantity</div>
              <div className="price">Price</div>
            </div>

            {filteredProducts.map(product => {
              const options = product.data.ProductData.options
              return (
                <div
                  key={product.variation.variantIdBase64}
                  className={`product`}
                >
                  <button
                    className="remove"
                    onClick={() =>
                      removeProductFromWishlist(product.variation.sku)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.502"
                      height="11.5"
                      viewBox="0 0 11.502 11.5"
                    >
                      <path
                        id="noun_cancel_2191250_1_"
                        data-name="noun_cancel_2191250 (1)"
                        d="M19.127,8.117a.833.833,0,0,0-1.18,0l-4.325,4.325L9.3,8.117A.834.834,0,0,0,8.117,9.3l4.325,4.325L8.117,17.947A.834.834,0,0,0,9.3,19.125L13.622,14.8l4.325,4.325a.834.834,0,1,0,1.182-1.177L14.8,13.622,19.127,9.3A.837.837,0,0,0,19.127,8.117Z"
                        transform="translate(-7.872 -7.872)"
                        fill="#a90011"
                      />
                    </svg>
                  </button>
                  {product.data.ProductData.images && (
                    <Link to={product.data.uri}>
                      <img
                        src={product.data.ProductData.images[0].image}
                        width={300}
                        alt={product.title}
                        loading="lazy"
                      />
                    </Link>
                  )}
                  <div className="product-info">
                    <Link to={product.data.uri}>
                      <p className="title">{product.data.title}</p>
                      {options && (
                        <>
                          {JSON.parse(options).map((variant, index) => (
                            <p key={variant.name}>
                              {variant.name}
                              <span>
                                {product.variation[`option${index + 1}`]}
                              </span>
                            </p>
                          ))}
                        </>
                      )}
                      <p>
                        SKU <span>{product.variation.sku}</span>
                      </p>
                    </Link>
                  </div>
                  <div className="quantity-control">
                    <button
                      onClick={() =>
                        updateQuantityHandler(
                          product.variation.sku,
                          product.quantity - 1
                        )
                      }
                    >
                      -
                    </button>
                    <input
                      type="text"
                      value={product.quantity}
                      onChange={e =>
                        updateQuantityHandler(
                          product.variation.sku,
                          e.target.value
                        )
                      }
                    />
                    <button
                      onClick={() =>
                        updateQuantityHandler(
                          product.variation.sku,
                          product.quantity + 1
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                  <p className="price">
                    <span>Price </span>&#163;
                    {(product.quantity * product.variation.price).toFixed(2)}
                  </p>
                  <div className="btn-wrap">
                    <AddToBasket
                      addProductToCartHandler={addProductToCartHandler}
                      product={product}
                      shopifyProductData={shopifyProductData}
                    />
                    {status === `cart-success-${product.variation.sku}` && (
                      <div className="cart-action">Product added to basket</div>
                    )}
                  </div>
                </div>
              )
            })}
            <p className="total">
              Total cost{" "}
              <span>
                &#163;
                {filteredProducts
                  .map(product => product.quantity * product.variation.price)
                  .reduce((a, b) => a + b)
                  .toFixed(2)}
              </span>
            </p>
            {status === "cart-error-noproducts" && (
              <div className="cart-action">
                There were no products available
              </div>
            )}
            {status === "cart-success-all" && (
              <div className="cart-action">
                Added all available items to your basket
              </div>
            )}
            {status === "cart-error-all" && (
              <div className="cart-action error">
                There was an error adding your products to basket
              </div>
            )}
            {!shopifyProductData.length && (
              <button className="checkout outofstock">Checking stock</button>
            )}
            {shopifyProductData.length && (
              <button
                className="checkout"
                onClick={() => addProductsToCartHandler()}
              >
                Add all to basket
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.76"
                  height="10.692"
                  viewBox="0 0 6.76 10.692"
                >
                  <path
                    id="Path_28883"
                    data-name="Path 28883"
                    d="M1497.349-439l-3.932,3.932L1489.485-439"
                    transform="translate(440.414 1498.763) rotate(-90)"
                    fill="none"
                    stroke="#00313c"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            )}
          </div>
        )}
      </section>
    </>
  )
}

export default connector(Wishlist)
