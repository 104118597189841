const styles = theme => ({
  pageTitle: {
    padding: "32px 0",
    [theme.breakpoints.up("md")]: {
      fontSize: "72px 0",
    },
    "& h1": {
      margin: 0,
    },
  },
  wishlistWrapper: {
    maxWidth: 1118,
    width: "calc(100% - 24px)",
    margin: "0 auto",
    "& .column-titles": {
      borderBottom: "2px solid #efefef",
      fontSize: 12,
      textTransform: "uppercase",
      letterSpacing: "0.152em",
      padding: "6px 0",
      margin: "32px auto 0",
      fontWeight: "bold",
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        "& div": {
          letterSpacing: "0.152em",
          "&:nth-of-type(1)": {
            flex: "0 0 50%",
          },
          "&:nth-of-type(2)": {
            flex: "0 0 16.666%",
          },
          "&:nth-of-type(3)": {
            flex: "0 0 8.333%",
            textAlign: "right",
          },
        },
      },
    },
    "& .product": {
      margin: "0 auto",
      display: "flex",
      padding: "24px 0",
      borderBottom: "2px solid #efefef",
      position: "relative",
      alignItems: "center",
      flexWrap: "wrap",
      "& img": {
        border: "2px solid #ACA39B",
        width: 70,
        height: 70,
        objectFit: "contain",
        backgroundColor: theme.common.white,
        display: "inline-block",
        marginRight: 24,
        borderRadius: 4,
      },
      "& button.remove": {
        border: "none",
        backgroundColor: "transparent",
        width: 24,
        height: 70,
        marginRight: 24,
        color: "#A90011",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .product-info": {
        width: "calc(100% - 142px)",
        [theme.breakpoints.up("md")]: {
          width: "calc(50% - 166px)",
          marginRight: 24,
        },
        "& p": {
          fontSize: 12,
          textTransform: "uppercase",
          letterSpacing: "0.152em",
          display: "inline-block",
          marginRight: 24,
          lineHeight: 1.2,
          color: "#ACA39A",
          marginTop: 0,
          marginBottom: 0,
          "& span": {
            display: "block",
            fontSize: 16,
            lineHeight: 1,
            textTransform: "none",
          },
          "&.title": {
            display: "block",
            fontSize: 18,
            lineHeight: "24px",
            color: theme.palette.primary.purple,
            letterSpacing: "0",
            fontWeight: "bold",
            fontFamily: theme.headerFont,
            marginBottom: 14,
            textTransform: "none",
          },
        },
      },
      "& .quantity-control": {
        width: 130,
        marginTop: 8,
        alignItems: "center",
        border: "none",
        marginRight: "auto",
        padding: 0,
        display: "block",
        backgroundColor: "#fff",
        [theme.breakpoints.up("md")]: {
          marginRight: 24,
          marginLeft: 0,
        },
        "& button, & input": {
          height: 50,
          fontSize: 16,
          fontWeight: 900,
          textAlign: "center",
          backgroundColor: "transparent",
          lineHeight: "50px",
        },
        "& button": {
          border: "1px solid #efefef",
          flex: "0 0 32px",
          width: 32,
          color: "rgba(0,0,0,0.35)",
          padding: 0,
          lineHeight: "44px",
          backgroundColor: "transparent",
          "&:nth-of-type(1)": {
            borderRight: "none",
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8,
          },
          "&:nth-of-type(2)": {
            borderLeft: "none",
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8,
          },
        },
        "& input": {
          flex: "0 0 66px",
          backgroundColor: "#fff",
          width: 66,
          border: "none",
          borderTop: "1px solid #efefef",
          borderBottom: "1px solid #efefef",
        },
      },
      "& .price": {
        fontWeight: 900,
        fontSize: 24,
        letterSpacing: "0",
        textAlign: "right",
        [theme.breakpoints.up("md")]: {
          marginLeft: "auto",
          marginTop: 20,
          display: "inline-block",
          verticalAlign: "top",
        },
        "& span": {
          fontWeight: "normal",
          textTransform: "uppercase",
          fontSize: 12,
          letterSpacing: "0.152em",
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      },
      "& .btn-wrap": {
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          width: "25%",
          textAlign: "right",
          paddingLeft: 12,
        },
        "& button.add": {
          fontSize: 18,
          fontFamily: theme.bodyFont,
          display: "table",
          maxWidth: 1118,
          padding: "16px 24px",
          position: "relative",
          fontWeight: "bold",
          lineHeight: 1.33,
          borderRadius: theme.border.radius,
          color: theme.palette.primary.navy,
          backgroundColor: theme.palette.primary.green,
          boxShadow: theme.shadow,
          border: "none",
          margin: "12px auto",
          [theme.breakpoints.up("md")]: {
            marginLeft: "auto",
            marginRight: 0,
          },
          "&.outofstock": {
            cursor: "default",
            color: "darkgray",
            backgroundColor: "lightgray",
          },
          "& svg": {
            marginLeft: 12,
            display: "inline-block",
            verticalAlign: "text-bottom",
          },
          "&:hover, &:focus": {
            color: theme.palette.primary.green,
            backgroundColor: theme.palette.primary.navy,
            "& svg": {
              "& *": { fill: theme.palette.primary.green },
            },
            "&.outofstock": {
              color: "darkgray",
              backgroundColor: "lightgray",
            },
          },
        },
      },
    },
    "& .total": {
      letterSpacing: "0.152em",
      fontSize: 12,
      textTransform: "uppercase",
      margin: "24px 0 40px",
      fontWeight: "900",
      "& span": {
        display: "block",
        fontWeight: "900",
        fontSize: 32,
        letterSpacing: "0.064em",
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "right",
        "& span": {
          width: "16.666%",
          display: "inline-block",
          verticalAlign: "baseline",
        },
      },
    },
    "& .cartUpdate": {
      margin: "12px auto 64px",
      fontSize: 18,
      lineHeight: 1.5,
      fontFamily: theme.subHeaderFont,
      display: "table",
      padding: "8px 24px",
      position: "relative",
      transition: "background-color .3s ease-in-out, color .3s ease-in-out",
      boxShadow: theme.shadow,
      "&:hover, &:focus": {
        outline: "none",
      },
      [theme.breakpoints.up("md")]: {
        margin: "12px 8.333% 120px auto",
      },
    },
    "& .checkout": {
      fontSize: 18,
      fontFamily: theme.bodyFont,
      display: "table",
      maxWidth: 1118,
      padding: "16px 24px",
      position: "relative",
      fontWeight: "bold",
      lineHeight: 1.33,
      borderRadius: theme.border.radius,
      color: theme.palette.primary.navy,
      backgroundColor: theme.palette.primary.green,
      boxShadow: theme.shadow,
      border: "none",
      marginLeft: "auto",
      marginBottom: 100,
      [theme.breakpoints.up("md")]: {
        marginBottom: 150,
      },
      "& svg": {
        marginLeft: 12,
        display: "inline-block",
      },
      "&.outofstock": {
        cursor: "default",
        color: "darkgray",
        backgroundColor: "lightgray",
      },
      "&:hover, &:focus": {
        color: theme.palette.primary.green,
        backgroundColor: theme.palette.primary.navy,
        "& svg": {
          "& *": {
            stroke: theme.palette.primary.green,
          },
        },
        "&.outofstock": {
          color: "darkgray",
          backgroundColor: "lightgray",
        },
      },
    },
    "& .cart-action": {
      position: "fixed",
      bottom: 12,
      right: 12,
      borderRadius: 4,
      padding: "12px 16px",
      backgroundColor: theme.palette.primary.navy,
      color: theme.palette.secondary.cream,
      fontSize: 12,
      letterSpacing: "0.016em",
      boxShadow: theme.shadow,
      zIndex: 1000,
      "& a": {
        color: "inherit",
      },
      "& .error": {
        backgroundColor: "darkred",
      },
    },
  },
})

export default styles
