import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Wishlist from "../components/Wishlist"
import CookiesConsent from "../components/CookiesConsent"
import FavouriteProducts from "../components/Products/Components/FavouriteProducts"
import { isBrowser } from "../helpers"

class Page extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    if (isBrowser) {
      return (
        <Layout
          meta={{
            ...this.props.data.wpPage.Meta,
            canonical: "/wish-list/",
          }}
          path={"/wish-list/"}
          cta={"a"}
          themeOptions={this.props.data.wp.themeOptions}
        >
          <Wishlist />
          <FavouriteProducts header="Best Sellers" />
          <CookiesConsent></CookiesConsent>
        </Layout>
      )
    } else {
      return (
        <Layout
          meta={{
            ...this.props.data.wpPage.Meta,
            canonical: "/quote-builder/",
          }}
          path={"a"}
          cta={"a"}
          themeOptions={this.props.data.wp.themeOptions}
        >
          <p>Loading...</p>
          <FavouriteProducts header="Best Sellers" />
          <CookiesConsent></CookiesConsent>
        </Layout>
      )
    }
  }
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Page
