import { graphql, useStaticQuery } from "gatsby"

export default function useAllProducts() {
  const data = useStaticQuery(graphql`
    query {
      allWpProduct(limit: 1000) {
        edges {
          node {
            id
            slug
            title
            link
            uri
            ProductData {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 920
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
              comparePrice
              description
              featuredImage
              options
              price
              images {
                image
                altText
              }
              productIdBase64
              title
              variants {
                inStock
                comparePrice
                image
                option1
                option2
                option3
                price
                quantity
                sku
                title
                weight
                variantIdBase64
              }
            }
          }
        }
      }
    }
  `)

  if (!data) {
    return null
  }

  return data.allWpProduct.edges.map(({ node }) => node)
}
