import React from "react"
import { makeStyles } from "@material-ui/styles"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./FavouriteProducts.styles"
import ProductCard from "./ProductCard"
import Slider from "react-slick"

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock"

import ArrowSvg from "../../ArrowSvg"

const useStyles = makeStyles(theme => styles(theme))

function FavouriteProducts(props) {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query favouriteProducts {
      allWpProduct(
        limit: 10
        sort: { fields: modifiedGmt, order: ASC }
        filter: { ProductMeta: { bestseller: { eq: true } } }
      ) {
        nodes {
          id
          slug
          title
          link
          ProductData {
            featuredImage
            price
            comparePrice
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
            variants {
              inStock
              comparePrice
              image
              option1
              option2
              option3
              price
              quantity
              sku
              title
              weight
              variantIdBase64
            }
          }
        }
      }
    }
  `)

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <span
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <ArrowSvg />
    </span>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <span
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <ArrowSvg />
    </span>
  )

  return (
    <div className={classes.favouriteProducts}>
      <div className="wrapper">
        {props.header !== null && (
          <CoreHeadingBlock
            attributes={{
              textAlign: "",
              anchor: "",
              className: "",
              content: props.header,
              level: 3,
              textColor: "initial",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
        )}
        <Slider
          arrows={true}
          dots={true}
          slidesToShow={4}
          slidesToScroll={1}
          infinite={false}
          nextArrow={<SlickArrowRight />}
          prevArrow={<SlickArrowLeft />}
          responsive={[
            {
              breakpoint: 920,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 320,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {data.allWpProduct.nodes.map((product, index) => {
            return (
              <ProductCard
                key={product.id}
                id={product.id}
                uri={product.link}
                title={product.title}
                image={product.ProductData.localFile}
                price={product.ProductData.price}
                skuCodes={product.ProductData.variants.map(v => v.sku)}
              />
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default FavouriteProducts
